class Accordion {

	constructor() {
		// dom elements
		this.accordionHeader = $('.accordion-header');
		
		if (this.accordionHeader.length) {
			this.init();			
		}
	}

	init() {

		// Handle toggling of accordion if clicked
		this.accordionHeader.on('click', (event) => {
			event.preventDefault();
			this.toggleAccordion(event);
		});

		// Handle toggling of accordion if enter key pressed
		this.accordionHeader.off('keypress').on('keypress', (event) => {
			event.preventDefault();
		    const keycode = (event.keyCode ? event.keyCode : event.which);

		    if( keycode == '13' || keycode == '32') {
		        this.toggleAccordion(event);
		    }
		});

	}

	toggleAccordion(event) {
		const accordion = $(event.currentTarget).closest('.accordion'),
			  accordionContent = accordion.children('.accordion-panel'),
			  accordionArrow = $(event.currentTarget).children('.fas'),
			  openAccordions = accordion.siblings('.accordion.open');

		if (!accordion.hasClass('open')) {
			// open accordion and close all others
			openAccordions.find('.accordion-header .fas').attr('aria-expanded', false);
			openAccordions.removeClass('open').find('.accordion-panel').slideUp(300);
			openAccordions.find('.accordion-header .fas').removeClass('fa-chevron-up').addClass('fa-chevron-down');
	
			accordion.addClass('open');
			$(event.currentTarget).attr('aria-expanded', true);
			accordionArrow.removeClass('fa-chevron-down').addClass('fa-chevron-up');
			accordionContent.slideDown('300', () => {
	
				// check if any tables exist and initilize if not already
				const responsiveTable = accordionContent.find($('.responsiveTable'));
				if (responsiveTable.length && !responsiveTable.hasClass('dataTable')) {
					responsiveTable.DataTable( {
						responsive: true,
						paging: false,
						searching: false,
						ordering:  false,
						info: false,
						autoWidth: false
					});
				}
			});
		}
		else {
			// close accordion
			$(event.currentTarget).attr('aria-expanded', false);
			accordion.removeClass('open');
			accordionArrow.removeClass('fa-chevron-up').addClass('fa-chevron-down');
			accordionContent.slideUp(300);
		}
	}
}

class Alerts {
    static main() {

        init();

        function init() {            
            const alertCloseBtns = document.querySelectorAll('.alert .js-close-alert');
            
            if (alertCloseBtns) {
                shouldRender();

                alertCloseBtns.forEach((closeBtn) => {
                    closeBtn.addEventListener('click', closeAlert);
                });
            }
        }

        // check sessionStorage if alert has been closed or not already
        function shouldRender() {{
            const alerts = document.querySelectorAll('.alert');

            alerts.forEach((alert) => {
                const alertId = alert.getAttribute('id');
                const shouldRender = sessionStorage.getItem('alert-'+alertId) !== "dismissed";

                if (shouldRender) {
                    alert.classList.add('show');
                }
            });
        }}

        function closeAlert(e) {
            e.preventDefault();
            const alertId = e.target.closest('.alert').getAttribute('id');
            
            sessionStorage.setItem('alert-' + alertId, "dismissed");
            document.getElementById(alertId).classList.remove('show');
        }
    }
}

class AlbumSelector {

    constructor() {
        this.albumSelector = $('#js-album-selector');

        if (this.albumSelector.length) {
            this.init();
        }
    }

    init() {
        this.SetFilterOnPageLoad();

        this.albumSelector.on('change', () => {
            this.jumpToPage();
        });
    }

    // on page load, set album option based off url
    SetFilterOnPageLoad() {
        const url = window.location.pathname;
        // if not on gallery landing page, change selectbox to active item
        if (url != '/gallery/') {
            this.albumSelector.val(url).change();
        }
        else {
            this.albumSelector[0].selectedIndex = 0;
        }
    }

    // go to page on album select change
    jumpToPage() {
        const url = this.albumSelector.val();

        if (url && url !== '') {
            window.location = url;
        }
        return false;
    }
}

class CookieMsg {
    static main() {
        
        // Check if the cookie alert cookie exists
        const cookieMsg = document.querySelector('.cookie-msg');
        
        if (cookieMsg) {
            init();
        }
        
        function init() {
            const id = cookieMsg.getAttribute('id') ? cookieMsg.getAttribute('id') : 'cookie-consent';
            const cookieExists = checkCookie(id);

            if (!cookieExists) {
                cookieMsg.classList.add('show');
    
                // Add an event listener to the accept button
                const acceptButton = cookieMsg.querySelector('.js-close-cookie');
                if (acceptButton) {
                    acceptButton.addEventListener('click', (e)=> {
                        e.preventDefault();
                        // Set a cookie to remember that the user has accepted the cookie alert
                        setCookie(id, 'true', 30); // The '30' here is the number of days the cookie will be valid
                        // Hide the cookie alert
                        cookieMsg.style.display = 'none';
                    });
                }
            }
        }        

        // Function to check if a cookie exists
        function checkCookie(name) {
            const cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.indexOf(name + '=') === 0) {
                    return true;
                }
            }
            return false;
        }

        // Function to set a cookie
        function setCookie(name, value, days) {
            let expires = '';
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = '; expires=' + date.toUTCString();
            }
            document.cookie = name + '=' + value + expires + '; path=/';
        }
    }
}

class FeaturedImageSlider {

    constructor() {
        this.sliderMain = $('.featured-image-slider .slider-main');
        this.sliderNav = $('.featured-image-slider .slider-nav');

        if (this.sliderMain.length && this.sliderNav.length) {
          setTimeout(() => {
            this.init();
          }, "500");
        }
    }

    init() {
        this.sliderMain.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            fade: true,
            asNavFor: this.sliderNav
        });

        this.sliderNav.slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            asNavFor: this.sliderMain,
            dots: false,
            arrows: false,
            centerMode: true,
            focusOnSelect: true,
            responsive: [                
                {
                  breakpoint: 992,
                  settings: {
                    slidesToShow: 3
                  }
                },
                {
                  breakpoint: 568,
                  settings: {
                    slidesToShow: 1
                  }
                }
            ]
        });
    }
}

class FoodCategories {
    
    constructor() {
        this.categoryItems = $('.js-food-category-item');
        this.menuDetails = $('.food-menu-categories .js-category-detail');

        if (this.categoryItems.length) {
            this.init();
        }
    }

    init() {

        this.showFirstCategory();

        // Handler for category items clicked
        this.categoryItems.on('click', (event)=> {
            event.preventDefault();
            $(event.currentTarget).blur();
            this.changeCategory(event);
        });
        
    }
    
    changeCategory(event) {
        const category = $(event.currentTarget).data('category');
        const clickedLI = $(event.currentTarget).parent('li');
        const clickedDetail = $('.food-menu-categories .js-category-detail[data-category="'+category+'"]');

        clickedLI.addClass('active').siblings().removeClass('active');
        clickedDetail.siblings().removeClass('active').stop().slideUp(300, ()=> {
            clickedDetail.slideDown(600).addClass('active');
            
            //$('html, body').animate({
            //    scrollTop: clickedDetail.offset().top - headerHeight
            //}, 2000);

        });
    }

    // on page load, show first category
    showFirstCategory() {
        this.categoryItems.first().parent('li').addClass('active');
        this.menuDetails.first().slideDown(300).addClass('active');
    }
}

/**
 *  Component Helpers
 */

/**
   * Theme Helpers
   */
const _COLORS_ = Object.freeze({
    0: 'primary',
    1: 'secondary',
    2: 'tertiary',
    4: 'quarternary',
    5: 'quinary',
    6: 'senary',
    7: 'gray-lite',
    8: 'gray-dark',
});

/**
   * Window Helpers
   */

  // Keyboard Enum
const _KEYBOARD_ = Object.freeze({
    'enter': 13,
    'arrow-u': 38,
    'arrow-r': 39,
    'arrow-d': 40,
    'arrow-l': 37,
    'esc': 27,
    'tab': 9,
    'space': 32
});

/**
   * Breakpoints are situatued such that
   * comparison would be "and down" --
   * 
   * if (window.innerWidth < _BREAKPOINTS_.sm) { ... }
   */
const _BREAKPOINTS_ = Object.freeze({
    'xs': 576,
    'sm': 767,
    'md': 991,
    'lg': 1199,
    'xl': 1399,
    'giant': 1799
});

class FullWidthImageCta {
    static main() {

        const fullWidthCtas = document.querySelectorAll('.full-width-image-cta');
        let useImg = "";
        
        fullWidthCtas.forEach((banner) => {
            init(banner);
        });

        function init(banner) {
            setTimeout(function() {                
                setBG(banner);
            }, 500);
            window.addEventListener('resize', (e) => setBG(banner));
        }

        // determines if on desktop or mobile and changes image accordingly
        function setBG(banner) {
            const desktopImg = banner.getAttribute('data-desktop-img');
            const mobileImg = banner.getAttribute('data-mobile-img');

            if (banner) {
                useImg = (!mobileImg || window.innerWidth >= _BREAKPOINTS_.lg) ? desktopImg : mobileImg;
                banner.style.backgroundImage = "url("+useImg+")";
            }
        }
    }
}

class PlainImageBanner {
    static main() {

        const banner = document.querySelector('.plain-image-banner');
        let useImg = "";

        if (banner) {
            init();
        }

        function init() {
            setTimeout(function() {
                setBG();
            }, 500);
            window.addEventListener('resize', setBG);
        }

        // determines if on desktop or mobile and changes image accordingly
        function setBG() {
            const desktopImg = banner.getAttribute('desktop-img');
            const mobileImg = banner.getAttribute('mobile-img');
            const heroImg = banner.querySelector('img');

            if (desktopImg && heroImg) {
                useImg = (!mobileImg || window.innerWidth >= _BREAKPOINTS_.md) ? desktopImg : mobileImg;

                heroImg.setAttribute('src', useImg);                
                heroImg.setAttribute('srcset', useImg);                
            }
        }
    }
}

class IconCard {
    static main() {

        const iconCards = document.querySelectorAll('.icon-card .heading a');
        const iconCardGallery = document.querySelectorAll('.icon-card-gallery');
        
        if (iconCards) {
            init();
        }

        function init() {
            iconCards.forEach((card) => {
                card.addEventListener('mouseenter', setHover);
                card.addEventListener('mouseleave', removeHover);
                card.addEventListener('focusin', setHover);
                card.addEventListener('focusout', removeHover);                
            });

            iconCardGallery.forEach((cardGAllery) => {
                cardGAllery.addEventListener('click', (e) => {startGallery(e);});
            });
        }

        function setHover(e) {
            e.target.closest('.icon-card').classList.add('hover');
        }

        function removeHover(e) {
            e.target.closest('.icon-card').classList.remove('hover');
        }

        function startGallery(e) {
            e.preventDefault();

            console.log(e.currentTarget);
            let imgs = [];
            const modal1Img = e.currentTarget.getAttribute('data-modal1');
            const modal2Img = e.currentTarget.getAttribute('data-modal2');
            const modal3Img = e.currentTarget.getAttribute('data-modal3');

            if (modal1Img) {
                imgs.push({
                    src: modal1Img,
                    type: 'image'
                });
            }
            if (modal2Img) {
                imgs.push({
                    src: modal2Img,
                    type: 'image'
                });
            }
            if (modal3Img) {
                imgs.push({
                    src: modal3Img,
                    type: 'image'
                });
            }
            // console.log(imgs);
            
            const fancybox = Fancybox.show(imgs);
        }
    }
}

class ImageCard {
    static main() {

        const cardLinks = document.querySelectorAll('.image-card a');
        
        if (cardLinks.length) {
            init();
        }

        function init() {            
            cardLinks.forEach((cardLink) => {
                const card = cardLink.closest('.image-card');
                
                cardLink.addEventListener('mouseenter', setHover);
                cardLink.addEventListener('mouseleave', removeHover);
                cardLink.addEventListener('focusin', setHover);
                cardLink.addEventListener('focusout', removeHover);
                
                if (card) {
                    const cardImage = card.querySelector('.card-img-top');

                    if (!cardImage) {
                        card.classList.add('no-image');                               
                    }
                }
            });
        }        

        function setHover(e) {
            e.target.closest('.image-card').classList.add('hover');
        }

        function removeHover(e) {
            e.target.closest('.image-card').classList.remove('hover');
        }
    }
}

/**
 * Lazy loads images that are set up properly.
 * For img tags: 
 *      <img
            class="img-load-lazy"
            src="small-placeholder.jpeg"
			data-src="full-sized-actual-img.jpg"
			data-srcset="full-sized-actual-img.jpg 2x, mobile-sized-actual-img.jpg 1x"
            alt=""
        >
            
    For BG Images: <div class="lazy-load-bg" style="background-image: url('/my-image.jpeg')"></div> 

    more info: https://developers.google.com/web/fundamentals/performance/lazy-loading-guidance/images-and-video
 */
class ImageLoader {

    constructor(options = {}) {
        this.queue = [];
        this.backgroundQueue = [];
        this.lazyImageObserver = null;
        this.lazyBgObserver = null;
        this.options = options;
    }

    initialize() {
        this.getImagesToLazyLoad();

        if (!("IntersectionObserver" in window)) {
            this.abort();
            return;
        }

        this.onContentLoaded();
        this.setGenericPlaceholder();

        this.queue.forEach((lazyImage) => {
            this.lazyImageObserver.observe(lazyImage);
        });

        this.backgroundQueue.forEach((lazyBg) => {
            this.lazyBgObserver.observe(lazyBg);
        });
    }

    getImagesToLazyLoad() {
        this.queue = document.querySelectorAll('.img-load-lazy');
        this.backgroundQueue = document.querySelectorAll('.lazy-load-bg');
    }

    setGenericPlaceholder() {
        if (this.options.genericImagePlaceholder) {
            this.queue.forEach((img) => {
                img.setAttribute('src', this.options.genericImagePlaceholder);
            });
        }

        if (this.options.genericBgPlaceholder) {
            this.backgroundQueue.forEach((bg) => {
                bg.style.backgroundColor = this.options.genericBgPlaceholder;
            });
        }
    }

    onContentLoaded() {
        this.lazyImageObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    lazyImage.src = lazyImage.dataset.src;
                    lazyImage.srcset = lazyImage.dataset.srcset;
                    lazyImage.classList.remove('img-load-lazy');
                    this.lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        this.lazyBgObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.remove('lazy-load-bg');
                    this.lazyBgObserver.unobserve(entry.target);
                }
            });
        });
    }

    abort() {
        for (let i = 0; i < this.queue.length; i++) {
            this.queue[i].setAttribute('src', this.queue[i].getAttribute('data-src'));
            this.queue[i].classList.remove('img-load-lazy');
        }

        for (let i = 0; i < this.backgroundQueue.length; i++) {
            this.backgroundQueue[i].classList.remove('lazy-load-bg');
        }
    }

}

class GalleryAlbum {
    
    constructor() {
        this.gallery = $('.masonry-grid');

        if (this.gallery.length) {
            this.init();
        }
    }

    init() {
        this.gallery.masonry({
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            gutter: 5,
            percentPosition: true,
        });
    }
}

class Menu {

    constructor() {
        this.mainMenu = $('.main-menu');
        this.nodes = $('.main-menu .node');
        this.toggleDropdowns = $('.main-menu .toggle-dropdown');
        this.parentLink = $('.main-menu .parent > a');
        this.headerCta = $('.header-cta .btn');
        this.mobileMenuBtn = $('.js-toggle-mobile-menu');
        this.breakpoint = 991.98; 
        this.beginPlanningBtn = $('.js-begin-planning');

        if (this.mainMenu) {
            this.init();
        }
    }

    init() {
        this.bindLinks();        
        this.moveSocialIcons();

        $(window).resize(() => { 
            this.bindLinks();
            this.moveSocialIcons(); 
        });

        this.beginPlanningBtn.on('click', function(e) {
            e.preventDefault();

            $('html, body').animate({
                scrollTop: $("#begin-planning-form").offset().top - $('header.site-header').outerHeight()
            }, 500);
            $('#begin-planning-form input:first').focus();
        });
    }

    bindLinks() {
        if ( $(window).outerWidth() > this.breakpoint) {
            this.desktopBindings();
        }
        else {
            this.mobileBindings();
        }
    }

    desktopBindings() {
        // Desktop top level
        this.nodes.off().on({
            mouseenter: (e) => {
                this.openSubmenu($(e.currentTarget));
            },
            mouseleave: (e) => {
                this.closeSubmenu($(e.currentTarget));
            }
        });

        // Keyboard Used to focus on top level
		this.parentLink.on({
            focus: (e) => {
                this.closeAllMenus();
                this.openSubmenu($(e.currentTarget.closest('.node')));
            }
		});

        // Keyboard Used to on header-cta, close all menus
		this.headerCta.off().on({
            focus: (e) => {
                this.closeAllMenus();
            }
		});
    }

    mobileBindings() {
        // mobile menu toggle
        this.mobileMenuBtn.off().on('click', (e) => {
            e.preventDefault();
            if (!this.mainMenu.hasClass('open')) {
                this.openMobileMenu();
            }
            else {
                Menu.closeMobileMenu();
            }
        });

        this.toggleDropdowns.on('click', (e) => {
            e.preventDefault();
            const linkNode = $(e.currentTarget).closest('.node');

            this.nodes.not(linkNode).removeClass('show').find('.dropdown').removeClass('show').stop().slideUp(300);

            if (!linkNode.hasClass('show')) {
                this.openSubmenu(linkNode);
            }
            else {
                this.closeSubmenu(linkNode);
            }
        });
    }

    openSubmenu(node) {
        $('body').addClass('menu-open');
        node.addClass('show').find('.dropdown').addClass('show').stop().slideDown(300);
    }

    closeSubmenu(node) {
        $('body').removeClass('menu-open');
        node.removeClass('show').find('.dropdown').removeClass('show').stop().slideUp(300);
    }

    closeAllMenus() {
        $('body').removeClass('menu-open');
        this.nodes.closest('.node').removeClass('show').find('.dropdown').removeClass('show').stop().slideUp(300);
    }
 
    openMobileMenu() {
        this.mainMenu.addClass('open').slideDown(300);
        this.mobileMenuBtn.find('svg use').attr('href', '/library/img/common.svg#times');
        this.mobileMenuBtn.children('.text').html('CLOSE');
        $('body').addClass('menu-open');
    }

    static closeMobileMenu() {
        const mainMenu = $('.main-menu');
        const mobileMenuBtn = $('.js-toggle-mobile-menu');

        mainMenu.removeClass('open').slideUp(300);
        mobileMenuBtn.find('svg use').attr('href', '/library/img/common.svg#bars');
        mobileMenuBtn.children('.text').html('MENU');
        $('body').removeClass('menu-open');
    }

    moveSocialIcons() {        
        const desktopWrapper = $('.utility-nav .social-col');
        const mobileWrapper = $('.main-menu .mobile-social-icons-wrapper');
        const socialIcons = $('.utility-nav .social-icons') ? $('.utility-nav .social-icons') : $('.main-menu .social-icons');

        if ( $(window).outerWidth() > this.breakpoint) {
            if ($('.utility-nav .social-icons').length != '1') {
                const socialIcons = $('.main-menu .social-icons');
                if (socialIcons.length) {
                    desktopWrapper.append(socialIcons);
                }
            }
        }
        else {
            if ($('.main-menu .social-icons').length != '1') {
                const socialIcons = $('.utility-nav .social-icons');
                if (socialIcons.length) {
                    mobileWrapper.append(socialIcons);
                }
            }
        }
    }
}

class MultiSelectbox {

    constructor() {
        this.selectBox = $('.js-multiselect');

        if (this.selectBox.length) {
            this.init();
        }
    }

    init() {
        this.selectBox.each(function() {
            const myPlaceholder = $(this).attr('data-placeholder');

            $(this).multiselect({
                placeholder: myPlaceholder
            });
        });
    }
}

class StorageHelper {

    /*
        :: Local Storage
    */
    static storeItem(key, val) {
        if (this.localStorageIsSupported()) {
            localStorage.setItem(key, val);
        }
    }

    static getItem(key) {
        if (this.localStorageIsSupported()) {
            return localStorage.getItem(key);
        }
    }

    /*
        :: Cookies
    */
    static setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        const expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    static getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    /*
        :: Helpers
    */
    static localStorageIsSupported() {
        let testKey = 'test', storage = window.sessionStorage;
        try {
            storage.setItem(testKey, '1');
            storage.removeItem(testKey);
            return true;
        } catch (error) {
            return false;
        }
    }

}

/*
    :: ADA Text Resizer
    --------------------
    The html gets a base font size of 62.5% to set a 1:10 font ratio
        EX: font-size: 20px; = font-size: 2rem;
    On increase or decrease, the html will get different classes

*/
const _storageKey = 'text_sizer_value';

class TextSizeChanger {

    static main() {
        $('.text-size-increase').on('click', (e) => {
            e.preventDefault();
            this.increaseFontSize();
        });
        $('.text-size-decrease').on('click', (e) => {
            e.preventDefault();
            this.decreaseFontSize();
        });

        this.getSetting();
    }

    static increaseFontSize() {
        const html = $(('html'));

        if(html.hasClass("text-large")) {
            html.removeClass("text-large");
            html.addClass("text-larger");
            this.saveSetting("text-larger");
        }
        else {
            if(html.hasClass("text-larger")) {
                html.removeClass("text-larger");
                html.addClass("text-largest");
                this.saveSetting("text-largest");
            }
            else {
                if(!html.hasClass("text-largest")) {
                    html.addClass("text-large");
                    this.saveSetting("text-large");
                }
            }
        }
    }

    static decreaseFontSize() {
        const html = $(('html'));

        if(html.hasClass("text-large")) {
            html.removeClass("text-large");
            localStorage.removeItem(_storageKey);
        }
        else {
            if(html.hasClass("text-larger")) {
                html.removeClass("text-larger");
                html.addClass("text-large");
                this.saveSetting("text-large");
            }
            else {
                if(html.hasClass("text-largest")) {
                    html.removeClass("text-largest");
                    html.addClass("text-larger");
                    this.saveSetting("text-larger");
                }
            }
        }
    }

    /*
        :: Helpers
    */
    static saveSetting(selector) {
        StorageHelper.storeItem(_storageKey, selector);
    }

    static getSetting(key) {
        let stored = StorageHelper.getItem(_storageKey);
        if (stored) {
            $(('html')).addClass(stored);
        }
    }

}

class TestimonialSlider {

	constructor() {
		this.slider = $('.testimonials-slider .slick-slider');

		if (this.slider.length) {
			this.init();
		}

	}

	init() {

		this.slider.slick({
		  	infinite: true,
		  	slidesToShow: 1,
		  	slidesToScroll: 1,
		  	dots: false,
		  	arrows: true,
		  	lazyLoad: 'ondemand',
		  	prevArrow: '<button type="button" class="slick-prev" aria-label="click to go to previous slide"><svg><use href="/library/img/common.svg#chevron-left"></use></svg></button>',
		  	nextArrow: '<button type="button" class="slick-next" aria-label="click to go to next slide"><svg><use href="/library/img/common.svg#chevron-right"></use></svg></button>'
		});

		$(window).on('resize', () => {
			this.slider.slick('resize');
		});
	}
}

class TeamMembers {
    static main() {
        const teamMemberSections = document.querySelectorAll('.team-members');
        
        if (teamMemberSections) {
            teamMemberSections.forEach((teamMembers) => {
                init(teamMembers);
            });
        }
        
        function init(teamMembers) {            
            const cards = teamMembers.querySelectorAll('.team-card').length;
            const loadMoreBtn = teamMembers.querySelector('.load-more-members-btn');

            if (cards && cards <= 4) { 
                teamMembers.classList.remove('fade-active');
            }

            if (loadMoreBtn) {
                loadMoreBtn.addEventListener('click', (e) => { expandModule(e, teamMembers); });
            }
        }

        function expandModule(e, teamMembers) {
            e.preventDefault();
            
            if (!e.currentTarget.classList.contains('expanded')) {
                teamMembers.classList.add('expanded');
                e.currentTarget.classList.add('expanded');
                e.currentTarget.querySelector('.text').innerHTML = "Show Less";
                const elementScrollHeight = teamMembers.scrollHeight;
                // console.log('elementScrollHeight: ', elementScrollHeight);
                
                teamMembers.style.maxHeight = elementScrollHeight.toString() + 'px';
            }
            else {
                teamMembers.classList.remove('expanded');
                e.currentTarget.classList.remove('expanded');
                e.currentTarget.querySelector('.text').innerHTML = "Load More";
                
                if (window.innerWidth > 991.8) {
                    teamMembers.style.maxHeight ='842px';
                } else {
                    teamMembers.style.maxHeight ='1500px';
            
                    teamMembers.scrollIntoView();
                }
            }
        }
    }
}

/**
 * Animation Helpers
 */

const fadeOut = (element) => {
    element.style.opacity = 1;
    (function fade() {
        if ((element.style.opacity -= 0.1) < 0) {
            element.style.display = 'none';
        } else {
            requestAnimationFrame(fade);
        }
    })();
};

const fadeIn = (element, display) => {
    element.style.opacity = 0;
    element.style.display = display || "block";
    (function fade() {
        let val = parseFloat(element.style.opacity);
        if ((val += 0.1) < 1) {
            element.style.opacity = val;
            requestAnimationFrame(fade);
        }
    })();
};

class ScrollToTop {
    static main() {
		
		const btn = document.querySelector('.btn-scroll-to-top');
		let isShown = false;
		
		if (btn) {
			init();
		}
        
		function init() {
			window.addEventListener('scroll', () => {
				if (window.scrollY > 350 && !isShown) {
					fadeIn(btn);
					isShown = true;
				} else if (window.scrollY <= 350 && isShown) {
					fadeOut(btn);
					isShown = false;
				}
			});
	
			btn.onclick = (e) => {
				e.preventDefault();
				window.scrollTo({top: 0, behavior: 'smooth'});
			};
		}		
	}
}

class Search {

    constructor() {
        this.searchBtn = $('.js-toggle-search');
        this.searchbox = $('header.site-header .searchbox');

        if (this.searchBtn) {
            this.init();
        }
    }

    init() {
        this.searchBtn.on('click', (e) => {
            e.preventDefault();
            if (!this.searchBtn.hasClass('open')) {
                this.openSearch();
            }
            else {
                Search.closeSearch();
            }
        });
        
        $(document).click((e) => { 
            var target = $(e.target);
            if (!target.closest('.search').length) {
                Search.closeSearch();
            }  
        });
    }
    
    openSearch() {
        Menu.closeMobileMenu();
        $('body').addClass('search-open');
        this.searchBtn.addClass('open').find('svg use').attr('href', '/library/img/common.svg#times');
        this.searchBtn.children('.text').html('CLOSE');
        this.searchbox.find('input').focus();
    }

    static closeSearch() {
        $('body').removeClass('search-open');
        const searchBtn = $('.js-toggle-search');
        // const searchbox = $('.js-toggle-search .searchbox');

        searchBtn.removeClass('open').find('svg use').attr('href', '/library/img/common.svg#search');
        searchBtn.blur().children('.text').html('SEARCH');
    }

    
}

/* Site Specific Javascript Component Imports */

const MainScripts = (() => {

	// Shared
	if ($('.text-size-increase').length || $('.text-size-decrease').length) { TextSizeChanger.main(); }
	if (typeof svg4everybody == 'function') { svg4everybody(); }
	ScrollToTop.main();
	
	const imageLoader = new ImageLoader();
	imageLoader.initialize();

	/*  Site Specific javascript modules  */
	new Accordion();
	Alerts.main();
	CookieMsg.main();
	new AlbumSelector();
	// new HeroHomepage();
	new FeaturedImageSlider();
	FullWidthImageCta.main();
	new FoodCategories();
	new GalleryAlbum();
	IconCard.main();
	ImageCard.main();
	new MultiSelectbox();
	new Menu();
	PlainImageBanner.main();
	new TestimonialSlider();
	TeamMembers.main();
	new Search();
	
})();
